export const PROJECTS = [
  'All Projects',
  'Portal Curp',
  'Certificate Assistant UK',
  'Asistente Certificado CO',
  'Asistente Certificado AR',
  'Asistente Certificado MX',
  'Constansias Paneles MX',
  'Asistente Certificado CL',
  'Asistente Certificado PE',
  'Asistente Certificado CR'
] as const;
