export const TAG_TYPES = {
  consult: 'consultOrders',
  service: 'serviceOrders',
  ordersServicePeru: 'ordersServicePeru',
  ordersByTablePeru: 'ordersByTablePeru',
  approvedServicesByDateRange: 'approvedServicesByDateRange',
  approvedServicesByProjectNameDateRange:
    'approvedServicesByProjectNameDateRange',
  ordersServicesUK: 'orderServicesUK',
  ordersByTableUK: 'ordersByTableUK'
};

export const API_PATH_ROUTES = {
  consultOrder: 'consult-orders/project',
  serviceOrder: 'service-orders/project',
  orderServicesByTablePeru: 'service-orders/peru/table',
  allServiceOrdersPeru: 'serivce-orders/peru',
  approvedServicesByDateRange: 'service-orders/approved/date-range',
  approvedServices: 'service-orders/approved',
  allServiceOrdersUK: 'service-orders/uk',
  orderServicesByTabelUK: 'service-orders/uk/table'
};
