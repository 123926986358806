import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  TAllOrderConsults,
  TAllOrderService,
  TOrderServicesUK,
  TPeruOrderServices
} from 'types';
import { IDateRange } from './oreders.types';

import { BASE_API_URL } from 'services/services.constants';
import { TAG_TYPES, API_PATH_ROUTES } from './orders.constants';

export const ordersApi = createApi({
  reducerPath: 'ordersApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_API_URL,
    credentials: 'include'
  }),
  tagTypes: [
    TAG_TYPES.consult,
    TAG_TYPES.service,
    TAG_TYPES.ordersByTablePeru,
    TAG_TYPES.ordersServicePeru,
    TAG_TYPES.approvedServicesByDateRange,
    TAG_TYPES.approvedServicesByProjectNameDateRange,
    TAG_TYPES.ordersServicesUK,
    TAG_TYPES.ordersByTableUK
  ],
  endpoints: (builder) => ({
    getConsultOrders: builder.query<
      TAllOrderConsults[],
      { projectName: string | null }
    >({
      query: ({ projectName }) =>
        `${API_PATH_ROUTES.consultOrder}/${projectName}`,
      providesTags: [TAG_TYPES.consult]
    }),
    getServicesOrdersByProjectName: builder.query<
      TAllOrderService[],
      { projectName: string | null }
    >({
      query: ({ projectName }) =>
        `${API_PATH_ROUTES.serviceOrder}/${projectName}`,
      providesTags: [TAG_TYPES.consult]
    }),
    getAllOrdersServicePeru: builder.query<TPeruOrderServices[], null>({
      query: () => `${API_PATH_ROUTES.allServiceOrdersPeru}`,
      providesTags: [TAG_TYPES.ordersServicePeru]
    }),
    getOrdersServiceByTablePeru: builder.query<
      TPeruOrderServices[],
      { tableName: string | null }
    >({
      query: ({ tableName }) =>
        `${API_PATH_ROUTES.orderServicesByTablePeru}/${tableName}`,
      providesTags: [TAG_TYPES.ordersByTablePeru]
    }),
    getAllOrdersServiceUK: builder.query<TOrderServicesUK[], null>({
      query: () => `${API_PATH_ROUTES.allServiceOrdersUK}`,
      providesTags: [TAG_TYPES.ordersServicesUK]
    }),
    getOrdersServiceByTableUK: builder.query<
      TOrderServicesUK[],
      { tableName: string | null }
    >({
      query: ({ tableName }) =>
        `${API_PATH_ROUTES.orderServicesByTabelUK}/${tableName}`,
      providesTags: [TAG_TYPES.ordersByTableUK]
    }),
    getApprovedServiceByDateRange: builder.query<
      TAllOrderService[],
      { startDate: string; endDate: string }
    >({
      query: ({ startDate, endDate }) =>
        `${API_PATH_ROUTES.approvedServicesByDateRange}?startDate=${startDate}&endDate=${endDate}`,
      providesTags: [TAG_TYPES.approvedServicesByDateRange]
    }),
    getApprovedServiceByProjectNameDateRange: builder.query<
      TAllOrderService[],
      { dateRange: IDateRange; projectRoute: string }
    >({
      query: ({ dateRange, projectRoute }) => {
        const { startDate, endDate } = dateRange;
        return `${API_PATH_ROUTES.approvedServices}/${projectRoute}/date-range?startDate=${startDate}&endDate=${endDate}`;
      },
      providesTags: [TAG_TYPES.approvedServicesByProjectNameDateRange]
    })
  })
});

export const {
  useGetConsultOrdersQuery,
  useGetServicesOrdersByProjectNameQuery,
  useGetAllOrdersServicePeruQuery,
  useGetOrdersServiceByTablePeruQuery,
  useGetApprovedServiceByDateRangeQuery,
  useGetApprovedServiceByProjectNameDateRangeQuery,
  useGetOrdersServiceByTableUKQuery,
  useGetAllOrdersServiceUKQuery
} = ordersApi;
